<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterSites">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.name" id="filter_title" :label="$t('site.filter.name')"></app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.title" id="filter_title" :label="$t('site.filter.title')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('site.filter.id')"></app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('site.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Input from '../form/inputs/Input'
import SiteFilter from '../../model/SiteFilter'

export default {
  name: 'SiteFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(SiteFilter)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterSites () {
      this.$store.commit('site/setPage', 1)
      this.$store.commit('site/setFilter', this.filter)
      this.$store.dispatch('site/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(SiteFilter)
      this.$store.commit('site/setFilter', this.filter)
      this.$store.dispatch('site/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['site/filter']
  }
}
</script>
